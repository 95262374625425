import { Modal } from "antd";
import parse from "html-react-parser";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./HowToUseModal.scss";

interface IProps {
  isVisible: boolean;
  terms?: string;
  callbackClose: () => void;
}

const HowToUseModal = (props: IProps) => {
  const handleOk = (): void => {
    props.callbackClose();
  };

  return (
    <Modal
      className="how-to-use-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="content">
        <h3 className="title">NASIL KULLANILIR?</h3>

        <p className="text">{props.terms && parse(props.terms)}</p>
        <div className="button-wrapper">
          <ButtonPrimary text="TAMAM" callback={handleOk} />
        </div>
      </div>
    </Modal>
  );
};

export default HowToUseModal;
