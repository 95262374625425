import axios from '../../../utilities/axios';
import {ActiveWheelDto} from '../../../models/dtos/activeWheel.dto';

export const getActiveWheelRequest = async (): Promise<ActiveWheelDto> => {
  try {
    const response = await axios.post<ActiveWheelDto>(
      `${process.env.REACT_APP_BASE_URL}/external-wheel/active`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
