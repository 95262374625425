import { Modal } from 'antd';
import warningIcon from '../../../assets/images/icon-warning.svg';
import { UI } from '../../../core/utilities/ui';
import ButtonPrimary from '../../Buttons/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../../Buttons/ButtonSecondary/ButtonSecondary';
import './GiveUpModal.scss';

interface IProps {
  isVisible: boolean;
  callbackClose: () => void;
  callbackGiveUp: () => void;
}
const GiveUpModal = (props: IProps) => {

  const handleOk = (): void => {
    props.callbackClose();
  }

  return (
    <Modal
      className="give-up-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="content">
        <img className="logo" src={warningIcon} alt="warning" />

        <p className="title">
          Hediyeni kaybediyorsun!
        </p>

        <p className="text">
          Hediyeni aktifleştirmediğin için hediye hakkını kaybedeceksin. Emin misin?
        </p>
        <ButtonPrimary
          text="GERİ DÖN"
          callback={handleOk}
        />
        <ButtonSecondary
          text="HEDİYE İSTEMİYORUM"
          callback={props.callbackGiveUp} />
      </div>
    </Modal>
  )
}

export default GiveUpModal;