import { message, Modal } from "antd";
import { ReactNode } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../../assets/images/copy.svg";
import gbIcon from "../../../assets/images/icon-gb.svg";
import { WheelOfferDto } from "../../../core/models/dtos/wheelOffer.dto";
import { WheelOfferType } from "../../../core/models/enums/wheelOfferType";
import { Constants } from "../../../core/utilities/constants";
import { Helpers } from "../../../core/utilities/helpers";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../Buttons/ButtonSecondary/ButtonSecondary";
import "./PrizeReadyModal.scss";

interface IProps {
  isVisible: boolean;
  prize: WheelOfferDto;
  code?: string;
  callbackClose: () => void;
  callbackHowToUse: () => void;
}
const PrizeReadyModal = (props: IProps) => {
  const renderLogo = (): ReactNode => {
    switch (props.prize.type) {
      case WheelOfferType.WALLET_POINT:
        return <img className="logo" src={gbIcon} alt="gb" />;
      case WheelOfferType.UNIQUE_CODE:
        return (
          <img className="logo m-0" src={props.prize.imageUrl} alt="prize" />
        );
      default:
        return (
          <img
            className="fener-logo logo"
            src={Constants.fenerLogo}
            alt="FenerLogo"
          />
        );
    }
  };

  const renderHeader = (): ReactNode => {
    switch (props.prize.type) {
      case WheelOfferType.UNIQUE_CODE:
        return (
          <>
            <p className="title">Tebrikler!</p>
            {renderLogo()}
            <p className="title">{props.prize.name}</p>
          </>
        );
      default:
        return (
          <>
            {renderLogo()}
            <p className="title">Hediyen hazır!</p>
          </>
        );
    }
  };

  const onCopy = (): void => {
    message.info({
      className: "copy-message",
      style: { marginTop: "20px" },
      icon: (
        <img
          src={copy}
          alt="copy"
          style={{ width: "15px", paddingBottom: "4px", marginRight: "4px" }}
        />
      ),
      content: "Kod kopyalandı.",
      duration: 2,
    });
  };

  const renderCopyCodeContent = (): ReactNode => {
    if (props.prize.type === WheelOfferType.UNIQUE_CODE && props.code) {
      return (
        <div className="copy-wrapper no-select">
          <CopyToClipboard text={props.code} onCopy={onCopy}>
            <div className="clipboard-content">
              <span className="clipboard-text">{props.code}</span>
              <img className="copy" src={copy} alt="copy" />
            </div>
          </CopyToClipboard>
        </div>
      );
    }
  };

  const renderTaraftarCardModal = (): ReactNode => {
    if (props.prize.type === WheelOfferType.WALLET_POINT) {
      return (
        <ButtonSecondary
          class="dark-blue"
          text="GERİ DÖN"
          callback={props.callbackClose}
        />
      );
    }
    if (props.prize.type === WheelOfferType.UNIQUE_CODE && props.prize.terms) {
      return (
        <ButtonSecondary
          text="NASIL KULLANIRIM?"
          callback={props.callbackHowToUse}
        />
      );
    }
  };

  const renderText = (): string => {
    switch (props.prize.type) {
      case WheelOfferType.WALLET_POINT:
        return "Hediyen cüzdanına yüklendi. İstediğin zaman kullanabilirsin.";
      case WheelOfferType.UNIQUE_CODE:
        return "Kodu alışverişin sırasında kullanarak hediyeni alabilirsin.";
      default:
        return "Çark-ı Fener’den kazandığın hediyeni en kısa sürede sana ulaştıracağız.";
    }
  };

  const renderBtnText = (): string => {
    switch (props.prize.type) {
      case WheelOfferType.WALLET_POINT:
        return "İNTERNET CÜZDANINA GİT";
      default:
        return "TAMAM";
    }
  };

  const handleOk = (): void => {
    switch (props.prize.type) {
      case WheelOfferType.WALLET_POINT:
        Helpers.sendPostMessage(Constants.walletEvent);
        break;
      case WheelOfferType.UNIQUE_CODE:
        Helpers.goToTaraftarCard();
        break;
      default:
        props.callbackClose();
        break;
    }
  };

  return (
    <Modal
      className="prize-ready-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="content">
        {renderHeader()}
        {renderCopyCodeContent()}
        <p className="text">{renderText()}</p>
        <ButtonPrimary text={renderBtnText()} callback={handleOk} />
        {renderTaraftarCardModal()}
      </div>
    </Modal>
  );
};

export default PrizeReadyModal;
