import { Button } from "antd";
import { ReactNode } from "react";
import "./ButtonSecondary.scss";

interface IProps {
  text: ReactNode;
  class?: string
  isSubmit?: boolean;
  callback?: Function;
}

const ButtonSecondary = (props: IProps) => {

  return (
    <Button
      id="button-secondary"
      type="link"
      className={props.class ?? ''}
      htmlType={props.isSubmit ? "submit" : "button"}
      onClick={() => props.callback ? props.callback() : {}}
    >
      <div className="d-flex">
        <span>{props.text}</span>
      </div>
    </Button>
  );
}

export default ButtonSecondary;
