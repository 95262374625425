export class Constants {
  public static readonly dateFormatUI: string = "DD.MM.yyyy";
  public static readonly dateFormatDB: string = "yyyy-MM-DD";
  public static readonly dateTimeFormatUI: string = "DD-MM-yyyy HH:mm:ss";

  public static readonly fenerLogo: string =
    "https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-logo.svg";
  public static readonly fenerLink: string = "https://www.fenerbahce.org";
  public static readonly taraftarCardLink: string =
    "https://taraftarkart.fenerbahce.org/";

  public static readonly goToTaraftarCardEvent: string = "goToTaraftarCard";
  public static readonly walletEvent: string = "goToWallet";

  public static readonly kgbLink: string =
    "https://links.kimgbister.com/bireysel-landing-button";

  public static readonly etkLink: string =
    "https://static.4play.com.tr/fener/TaraftarPlus_ETK_web_mobil.pdf";
  public static readonly kvkkLink: string =
    "https://static.4play.com.tr/Taraftar_Kart_Aydinlatma_Metni.pdf";
  public static readonly kkLink: string =
    "https://static.4play.com.tr/fener/cark-ifener2025katilimkosullari.pdf";
}
