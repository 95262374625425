import { Form, Input } from "antd";
import { ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FormValuesUser } from "../../../../core/models/custom/formValuesUser";
import { WheelOfferDto } from "../../../../core/models/dtos/wheelOffer.dto";
import { WheelAttrType } from "../../../../core/models/enums/wheelAttrType";
import { UserRequest } from "../../../../core/models/requests/user.request";
import { UserFetchState } from "../../../../core/services/userService/getMe/types";
import { updateUserDetail } from "../../../../core/services/userService/updateUser/actions";
import { UpdateUserState } from "../../../../core/services/userService/updateUser/types";
import { IStore } from "../../../../core/utilities/reducers";
import ButtonPrimary from "../../../Buttons/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Buttons/ButtonSecondary/ButtonSecondary";
import "./UserForm.scss";
import { Constants } from "../../../../core/utilities/constants";

interface IProps {
  prize?: WheelOfferDto;
  userFetchState: UserFetchState;
  updateUserState: UpdateUserState;
  updateUserDetail: (userRequest: UserRequest) => void;
  callbackSubmit: () => void;
  callbackGiveUp: () => void;
}

const UserForm = (props: IProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.userFetchState.data) {
      const { firstName, lastName, email, address } = props.userFetchState.data;
      const name = firstName && lastName ? `${firstName} ${lastName}` : "";
      form.setFieldsValue({
        name,
        email,
        address,
      });
    }
  }, [form, props.userFetchState]);

  useEffect(() => {
    if (!props.updateUserState.loading && props.updateUserState.data) {
      props.callbackSubmit();
    }
    // eslint-disable-next-line
  }, [props.updateUserState]);

  function handleFinish(values: FormValuesUser) {
    const spaceIndex = values.name.lastIndexOf(" ");
    let firstName = values.name.slice(0, spaceIndex);
    let lastName = values.name.slice(spaceIndex + 1);
    if (spaceIndex === -1) {
      firstName = values.name;
      lastName = "";
    }
    const userRequest: UserRequest = {
      firstName,
      lastName,
      email: values.email,
      address: values.address,
    };
    props.updateUserDetail(userRequest);
  }

  const renderAddressInput = (): ReactNode => {
    if (props.prize && props.prize.attr1 === WheelAttrType.ADDRESS_REQUIRED) {
      return (
        <Form.Item
          name="address"
          label="Adres"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
      );
    }
  };

  return (
    <Form
      id="user-form"
      className="app-form"
      form={form}
      requiredMark={false}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Form.Item
        name="name"
        label="Ad Soyad"
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Mail Adresi"
        rules={[
          { required: true, message: "" },
          { type: "email", message: "" },
        ]}
      >
        <Input />
      </Form.Item>
      {renderAddressInput()}
      <Form.Item className="mb-1">
        <ButtonPrimary
          text="DEVAM ET"
          loading={props.updateUserState.loading}
          isSubmit={true}
        />
      </Form.Item>

      <p className="link mt-2 mb-0">
        Devam ederek&nbsp;
        <a
          className="contract-link"
          target="_blank"
          rel="noreferrer"
          href={Constants.kvkkLink}
        >
          KVKK Aydınlatma Metni
        </a>
        &nbsp;'ni onaylamış olursun.
      </p>
      <Form.Item className="mb-0">
        <ButtonSecondary text="İSTEMİYORUM" callback={props.callbackGiveUp} />
      </Form.Item>
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      updateUserDetail,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    userFetchState: store.getMe,
    updateUserState: store.updateUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
