import {
  WHEEL_HASH_CONSUME_FAIL,
  WHEEL_HASH_CONSUME_RESET,
  WHEEL_HASH_CONSUME_START,
  WHEEL_HASH_CONSUME_SUCCESS,
} from "./constants";
import { WheelHashConsumeActions, WheelHashConsumeState } from "./types";

const initialState: WheelHashConsumeState = {
  loading: false,
  data: false,
  error: undefined
}

const reducer = (
  state = initialState,
  action: WheelHashConsumeActions,
): WheelHashConsumeState => {
  switch (action.type) {
    case WHEEL_HASH_CONSUME_START:
      return {
        ...initialState,
        loading: true
      };
    case WHEEL_HASH_CONSUME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case WHEEL_HASH_CONSUME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case WHEEL_HASH_CONSUME_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default reducer;
