import { Modal } from "antd";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import wheelIcon from "../../../assets/images/limit-reached-cark.svg";
import { GetActiveWheelState } from "../../../core/services/externalWheelService/getActiveWheel/types";
import { IStore } from "../../../core/utilities/reducers";
import { UI } from "../../../core/utilities/ui";
import "./LimitReachedModal.scss";

interface IProps {
  getActiveWheelState: GetActiveWheelState;
}
const LimitReachedModal = (props: IProps) => {
  const isModalVisible = (): boolean => {
    return (
      !props.getActiveWheelState.loading &&
      !!props.getActiveWheelState.error &&
      props.getActiveWheelState.error.status === 404
    );
  };

  //   const renderTaraftarCardButton = (): ReactNode => {
  //     return (
  //       <div className="my-2">
  //         <ButtonPrimary
  //           text="TARAFTAR KART’A GİT"
  //           callback={() => (window.location.href = Constants.taraftarCardLink)}
  //         />
  //       </div>
  //     );
  //   };
  //   const renderBackButton = (): ReactNode => {
  //     return (
  //       <div className="mt-2">
  //         <ButtonSecondary
  //           class="dark-blue"
  //           text="GERİ DÖN"
  //           callback={() => (window.location.href = Constants.fenerLink)}
  //         />
  //       </div>
  //     );
  //   };
  const renderModalContent = (): React.ReactNode => {
    return (
      <div className="content">
        <img className="logo" src={wheelIcon} alt="cark" />
        <p className="title">Çok yakında yeniden açılacak!</p>
        <p className="text">
          Çarkın süresi doldu, sürpriz dolu yeni çark için takipte kal!
        </p>
        {/* <p className='text'>Merak etme, Taraftar Kart Çark'ı her hafta hediyeler kazandırmaya devam ediyor!</p> */}
        {/* {renderTaraftarCardButton()}
        {renderBackButton()} */}
      </div>
    );
  };

  return (
    <Modal
      className="limit-reached-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={isModalVisible()}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="body">{renderModalContent()}</div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = (store: IStore) => {
  return {
    getActiveWheelState: store.getActiveWheel,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitReachedModal);
