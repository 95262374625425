import {
  SEND_OTP_FAIL,
  SEND_OTP_RESET,
  SEND_OTP_START,
  SEND_OTP_SUCCESS
} from './constants';
import { SendOtpActions, SendOtpState } from './types';

const initialState: SendOtpState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: SendOtpActions,
): SendOtpState => {
  switch (action.type) {
    case SEND_OTP_START:
      return {
        ...initialState,
        loading: true,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SEND_OTP_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
