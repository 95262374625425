
import axios from '../../../utilities/axios';

export const wheelConsume = async (hash: string): Promise<void> => {
  try {
    const response = await axios.patch<void>(
      `${process.env.REACT_APP_BASE_URL_WHEEL}/tickets/consume`,
      { hash },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
