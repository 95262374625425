import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import carkIcon from "../../assets/images/fbtk_cark.png";
import ButtonPrimary from "../../components/Buttons/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../components/Buttons/ButtonSecondary/ButtonSecondary";
import FanCardModal from "../../components/Modals/FanCardModal/FanCardModal";
import LimitReachedModal from "../../components/Modals/LimitReachedModal/LimitReachedModal";
import MsisdnModal from "../../components/Modals/MsisdnModal/MsisdnModal";
import NoRightModal from "../../components/Modals/NoRightModal/NoRightModal";
import OtpModal from "../../components/Modals/OtpModal/OtpModal";
import { getActiveWheel } from "../../core/services/externalWheelService/getActiveWheel/actions";
import { LoginMsisdnState } from "../../core/services/externalWheelService/loginMsisdn/types";
import { Constants } from "../../core/utilities/constants";
import { Helpers } from "../../core/utilities/helpers";
import { IStore } from "../../core/utilities/reducers";
import "./HomePage.scss";

interface IProps {
  loginMsisdnState: LoginMsisdnState;
  getActiveWheel: () => void;
}
class HomePage extends Component<IProps> {
  private renderWheelButton(): ReactNode {
    return (
      <ButtonPrimary
        class="button-yellow my-3"
        text="ÇARKI ÇEVİR"
        callback={() => this.props.getActiveWheel()}
      />
    );
  }
  private renderTaraftarCardButton(): ReactNode {
    return (
      <ButtonSecondary
        class="white"
        text="TARAFTAR KART'A GİT"
        callback={() => Helpers.goToTaraftarCard()}
      />
    );
  }

  render() {
    return (
      <div id="home-page" className="page">
        <div className="page-content">
          <img
            className="fener-logo mb-3"
            src={Constants.fenerLogo}
            alt="FenerLogo"
          />
          <h1 className="home-page-heading">
            ÇARK-I FENER’E
            <br />
            HOŞ GELDİN!
          </h1>
          <p className="home-page-context">
            Değerli Taraftar Kart üyesi, pek çok sürpriz hediye çarkta seni
            bekliyor.
          </p>
          {this.renderWheelButton()}
          {this.renderTaraftarCardButton()}
          <div className="mpi-text">
          Bu kampanya Lacivert Girişim Teknoloji Tic .A.Ş. tarafından MPİ’nin 24.12.2024 tarihli ve EE40453693-255.05.02-65964 sayılı yazısı ile izin kapsamı dışında düzenlenmektedir.
          </div>
        </div>
        <MsisdnModal />
        <OtpModal />
        <LimitReachedModal />
        <NoRightModal />
        <FanCardModal
          isVisible={
            !!this.props.loginMsisdnState.data &&
            !this.props.loginMsisdnState.data.hash
          }
          logo={carkIcon}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getActiveWheel,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    loginMsisdnState: store.loginMsisdn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
