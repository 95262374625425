import { Dispatch } from "redux";
import { ErrorDto } from "../../../models/dtos/error.dto";
import { Errors } from "../../../utilities/errors";
import {
  WHEEL_HASH_CONSUME_FAIL,
  WHEEL_HASH_CONSUME_RESET,
  WHEEL_HASH_CONSUME_START,
  WHEEL_HASH_CONSUME_SUCCESS
} from "./constants";
import { wheelConsume } from "./repository";
import {
  WheelHashConsumeFailAction,
  WheelHashConsumeResetAction,
  WheelHashConsumeStartAction,
  WheelHashConsumeSuccessAction
} from "./types";

const wheelHashConsumeStartAction = (): WheelHashConsumeStartAction => {
  return {
    type: WHEEL_HASH_CONSUME_START,
  }
}
const wheelHashConsumeSuccessAction = (payload: boolean): WheelHashConsumeSuccessAction => {
  return {
    type: WHEEL_HASH_CONSUME_SUCCESS,
    payload: payload,
  }
}
const wheelHashConsumeFailAction = (error: ErrorDto): WheelHashConsumeFailAction => {
  return {
    type: WHEEL_HASH_CONSUME_FAIL,
    error: error,
  }
}
export const wheelConsumeHash = (hash: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(wheelHashConsumeStartAction());
    await wheelConsume(hash);
    dispatch(wheelHashConsumeSuccessAction(true));
  } catch (error) {
    dispatch(wheelHashConsumeFailAction(Errors.getErrorDtoFromApiError(error)));
  }
}

const wheelHashConsumeResetAction = (): WheelHashConsumeResetAction => {
  return {
    type: WHEEL_HASH_CONSUME_RESET,
  }
}
export const wheelResetHashConsume = () => (
  dispatch: Dispatch
) => {
  dispatch(wheelHashConsumeResetAction());
}
