import { Checkbox, Form, Modal } from "antd";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { FormValuesMsisdn } from "../../../core/models/custom/formValuesMsisdn";
import { SendOtp } from "../../../core/models/custom/sendOtp";
import { getActiveWheelReset } from "../../../core/services/externalWheelService/getActiveWheel/actions";
import { GetActiveWheelState } from "../../../core/services/externalWheelService/getActiveWheel/types";
import { sendOtp } from "../../../core/services/externalWheelService/sendOtp/actions";
import { Constants } from "../../../core/utilities/constants";
import { IStore } from "../../../core/utilities/reducers";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./MsisdnModal.scss";

interface IProps {
  getActiveWheelState: GetActiveWheelState;
  getActiveWheelReset: () => void;
  sendOtp: (request: SendOtp) => void;
}

const MsisdnModal = (props: IProps) => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [isPhoneError, setPhoneError] = useState<boolean>(false);

  const isModalVisible = (): boolean => {
    return (
      !props.getActiveWheelState.loading && !!props.getActiveWheelState.data
    );
  };

  const onFinish = (values: FormValuesMsisdn) => {
    const msisdn = values.msisdn.replaceAll(" ", "");
    if (phone.length < 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
      props.sendOtp({ msisdn, marketingConsent: values.marketingConsent });
      props.getActiveWheelReset();
    }
  };

  const onFinishFailed = () => {
    setPhoneError(true);
  };

  const onChangeInput = (phone: string) => {
    const phoneInput = phone.replace(/\s+/g, "");
    setPhone(phoneInput);
    phoneInput.length === 10 && setPhoneError(false);
  };

  const handleCancelModal = () => {
    props.getActiveWheelReset();
  };

  const renderModalContent = (): React.ReactNode => {
    return (
      <div className="content">
        <img className="logo fener-logo" src={Constants.fenerLogo} alt="cark" />
        <p className="title mb-4">Telefon numaranı gir</p>
        <Form
          id="msisdn-form"
          className="app-form mt-2"
          name="msisdn-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="on"
          initialValues={{
            marketingConsent: false,
          }}
        >
          <Form.Item
            name="msisdn"
            rules={[
              {
                required: true,
                message: "Telefon numaranı kontrol ederek tekrar gir.",
              },
            ]}
            hasFeedback={isPhoneError}
            validateStatus={isPhoneError ? "warning" : ""}
            help={
              isPhoneError ? "Telefon numaranı kontrol ederek tekrar gir." : ""
            }
          >
            <PhoneInput
              containerClass="phone-input"
              inputClass={`ant-input phone ${
                isPhoneError ? "error-border" : ""
              }`}
              country="tr"
              value={phone}
              placeholder=""
              countryCodeEditable={false}
              onChange={(phone) => {
                onChangeInput(phone);
                setPhone(phone);
              }}
            />
          </Form.Item>

          <Form.Item name="marketingConsent" valuePropName="checked">
            <Checkbox>
              {" "}
              Tüm kampanya ve fırsatlardan haberdar olabilmek için&nbsp;
              <a
                className="contract-link"
                target="_blank"
                rel="noreferrer"
                href={Constants.etkLink}
              >
                Ticari Elektronik İleti Onayı
              </a>
              &nbsp;’nı veriyorum.
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <ButtonPrimary text="DEVAM ET" isSubmit={true} />
          </Form.Item>
        </Form>
        <p className="link">
          Devam ederek&nbsp;
          <a
            className="contract-link"
            target="_blank"
            rel="noreferrer"
            href={Constants.kkLink}
          >
            Kullanım Koşulları
          </a>
          &nbsp;ve&nbsp;
          <a
            className="contract-link"
            target="_blank"
            rel="noreferrer"
            href={Constants.kvkkLink}
          >
            KVKK Aydınlatma Metni
          </a>
          'ni onaylamış olursun.
        </p>
      </div>
    );
  };

  return (
    <Modal
      className="msisdn-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={isModalVisible()}
      closable={true}
      centered
      footer={false}
      destroyOnClose
      width={342}
      onCancel={handleCancelModal}
    >
      <div className="body">{renderModalContent()}</div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      sendOtp,
      getActiveWheelReset,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    getActiveWheelState: store.getActiveWheel,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MsisdnModal);
