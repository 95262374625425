import { Modal } from "antd";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import wheelIcon from "../../../assets/images/no-right-cark.svg";
import { LoginMsisdnState } from "../../../core/services/externalWheelService/loginMsisdn/types";
import { Helpers } from "../../../core/utilities/helpers";
import { IStore } from "../../../core/utilities/reducers";
import { UI } from "../../../core/utilities/ui";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./NoRightModal.scss";

interface IProps {
  loginMsisdnState: LoginMsisdnState;
}
const NoRightModal = (props: IProps) => {
  const isModalVisible = (): boolean => {
    return props.loginMsisdnState.error?.status === 400;
  };

  const renderTaraftarCardButton = (): ReactNode => {
    return (
      <div className="my-2">
        <ButtonPrimary
          text="TARAFTAR KART’A GİT"
          callback={() => Helpers.goToTaraftarCard()}
        />
      </div>
    );
  };
  // const renderBackButton = (): ReactNode => {
  //   return (
  //     <div className="mt-2">
  //       <ButtonSecondary
  //         class="dark-blue"
  //         text="GERİ DÖN"
  //         callback={() => (window.location.href = Constants.fenerLink)}
  //       />
  //     </div>
  //   );
  // };
  const renderModalContent = (): React.ReactNode => {
    return (
      <div className="content">
        <img className="logo" src={wheelIcon} alt="cark" />
        <p className="title">Çarkı çevirdin!</p>
        <p className="text">
          Merak etme, Taraftar Kart’ta her hafta çark hakkı kazanmaya devam
          edeceksin.
        </p>
        {renderTaraftarCardButton()}
      </div>
    );
  };

  return (
    <Modal
      className="no-right-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={isModalVisible()}
      centered
      closable={false}
      footer={false}
      destroyOnClose
      width={342}
    >
      <div className="body">{renderModalContent()}</div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = (store: IStore) => {
  return {
    loginMsisdnState: store.loginMsisdn,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoRightModal);
