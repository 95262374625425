import { Dispatch } from 'redux';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { ActiveWheelDto } from '../../../models/dtos/activeWheel.dto';
import {
  GET_ACTIVE_WHEEL_FAIL,
  GET_ACTIVE_WHEEL_RESET,
  GET_ACTIVE_WHEEL_START,
  GET_ACTIVE_WHEEL_SUCCESS,
} from './constants';
import { getActiveWheelRequest } from './repository';
import {
  GetActiveWheelFailAction,
  GetActiveWheelResetAction,
  GetActiveWheelStartAction,
  GetActiveWheelSuccessAction,
} from './types';
import { Errors } from '../../../utilities/errors';

const getActiveWheelStartAction = (): GetActiveWheelStartAction => {
  return {
    type: GET_ACTIVE_WHEEL_START,
  };
};
const getActiveWheelSuccessAction = (payload: ActiveWheelDto): GetActiveWheelSuccessAction => {
  return {
    type: GET_ACTIVE_WHEEL_SUCCESS,
    payload: payload,
  };
};
const getActiveWheelFailAction = (error: ErrorDto): GetActiveWheelFailAction => {
  return {
    type: GET_ACTIVE_WHEEL_FAIL,
    error: error,
  };
};
const getActiveWheelResetAction = (): GetActiveWheelResetAction => {
  return {
    type: GET_ACTIVE_WHEEL_RESET,
  };
};

export const getActiveWheel = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getActiveWheelStartAction());
    const response = await getActiveWheelRequest();
    dispatch(getActiveWheelSuccessAction(response));
  } catch (error) {
    dispatch(getActiveWheelFailAction(Errors.getErrorDtoFromApiError(error)));
  }
};

export const getActiveWheelReset = () => (dispatch: Dispatch) => {
  dispatch(getActiveWheelResetAction());
};
