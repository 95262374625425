
import { UserRequest } from '../../../models/requests/user.request';
import axios from '../../../utilities/axios';

export const updateUser = async (userRequest: UserRequest): Promise<void> => {
  try {
    const response = await axios.patch<void>(
      `${process.env.REACT_APP_BASE_URL}/users/me`,
      userRequest
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
