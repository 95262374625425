import {
  USER_FETCH_FAIL,
  USER_FETCH_RESET,
  USER_FETCH_START,
  USER_FETCH_SUCCESS
} from "./constants";
import { UserFetchActions, UserFetchState } from "./types";

const initialState: UserFetchState = {
  loading: false,
  data: undefined,
  error: undefined
}

const reducer = (
  state = initialState,
  action: UserFetchActions,
): UserFetchState => {
  switch (action.type) {
    case USER_FETCH_START:
      return {
        ...initialState,
        loading: true
      };
    case USER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case USER_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case USER_FETCH_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default reducer;
