import moment from "moment";
import "moment/locale/tr";
import { Constants } from "./constants";
import { history } from "./history";

export class Helpers {
  static wait = (ms: number = 1000) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  static isEnvProd(): boolean {
    const env: string = `${process.env.REACT_APP_ENV}`;
    return env === "production";
  }

  static getQueryParam(query: string): string | null {
    const searchParams: URLSearchParams = new URLSearchParams(
      history.location.search
    );
    return searchParams.get(query);
  }

  static getLocaleDate(date?: string, isTime: boolean = true): string {
    moment.locale("tr");
    return !!date
      ? moment(date).format(
          isTime ? Constants.dateTimeFormatUI : Constants.dateFormatUI
        )
      : "";
  }

  static isTcknValid(tckn: string): boolean {
    if (!tckn || tckn.length !== 11) {
      return false;
    } else {
      // 1-3-5-7-9. haneler toplamından, 2-4-6-8. haneleri çıkar.
      // 10'a böl, kalan sayı 10. haneyi verecek
      let odds = 0;
      let evens = 0;

      for (let i = 0; i <= 8; i += 2) odds += Number.parseInt(tckn[i]);

      for (let i = 1; i <= 7; i += 2) evens += Number.parseInt(tckn[i]);

      if ((7 * odds - evens) % 10 !== Number.parseInt(tckn[9])) return false;

      // 1-10. haneler toplamının 10'a bölümünden kalan, 11. haneyi verecek
      let total = 0;
      for (let i = 0; i <= 9; i++) {
        total += Number.parseInt(tckn[i]);
      }
      return total % 10 === Number.parseInt(tckn[10]);
    }
  }

  static sendPostMessage(message: string): void {
    window.parent.postMessage(message, "*");
  }

  static goToTaraftarCard(): void {
    this.sendPostMessage(Constants.goToTaraftarCardEvent);
    window.location.href = Constants.taraftarCardLink;
  }
}
