import { Dispatch } from "redux";
import { ErrorDto } from "../../../models/dtos/error.dto";
import { UserMeDto } from "../../../models/dtos/userMe.dto";
import { Errors } from "../../../utilities/errors";
import {
  USER_FETCH_FAIL,
  USER_FETCH_RESET,
  USER_FETCH_START,
  USER_FETCH_SUCCESS
} from "./constants";
import { getMe } from "./repository";
import {
  UserFetchFailAction,
  UserFetchResetAction,
  UserFetchStartAction,
  UserFetchSuccessAction
} from "./types";

const userFetchStartAction = (): UserFetchStartAction => {
  return {
    type: USER_FETCH_START,
  }
}
const userFetchSuccessAction = (payload: UserMeDto): UserFetchSuccessAction => {
  return {
    type: USER_FETCH_SUCCESS,
    payload: payload,
  }
}
const userFetchFailAction = (error: ErrorDto): UserFetchFailAction => {
  return {
    type: USER_FETCH_FAIL,
    error: error,
  }
}
export const userFetchDetail = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(userFetchStartAction());
    const userMe = await getMe();
    dispatch(userFetchSuccessAction(userMe));
  } catch (error) {
    dispatch(userFetchFailAction(Errors.getErrorDtoFromApiError(error)));
  }
}

const userFetchResetAction = (): UserFetchResetAction => {
  return {
    type: USER_FETCH_RESET,
  }
}
export const userFetchReset = () => (
  dispatch: Dispatch
) => {
  dispatch(userFetchResetAction());
}
