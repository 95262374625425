import { Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { WheelOfferDto } from "../../../core/models/dtos/wheelOffer.dto";
import { UpdateUserState } from "../../../core/services/userService/updateUser/types";
import { IStore } from "../../../core/utilities/reducers";
import { UI } from "../../../core/utilities/ui";
import UserForm from "./UserForm/UserForm";
import "./UserFormModal.scss";

interface IProps {
  isVisible: boolean;
  prize?: WheelOfferDto;
  updateUserState: UpdateUserState;
  callbackClose: () => void;
  callbackGiveUp: () => void;
}

const UserFormModal = (props: IProps) => {

  const handleSubmitUserForm = () => {
    props.callbackClose();
  }

  return (
    <Modal
      className="user-form-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={props.isVisible}
      centered
      closable={false}
      footer={false}
      destroyOnClose
    >
      <div className="content">
        <p className="title">
          Hediyeni hazırlıyoruz!
        </p>

        <p className="text">
          Çark-ı Fener’den kazandığın hediyeni sana ulaştırabilmemiz  için lütfen formu doldur.
        </p>
        <UserForm
          prize={props.prize}
          callbackSubmit={handleSubmitUserForm}
          callbackGiveUp={props.callbackGiveUp} />
      </div>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {},
    dispatch,
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    updateUserState: store.updateUser
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFormModal);
