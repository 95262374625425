import { Spin } from "antd";
import React, { Component, ReactNode } from "react";
import {
  StyleType,
  WheelData,
} from "react-custom-roulette/dist/components/Wheel/types";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import carkTitle from "../../assets/images/cark-title.svg";
import carkIcon from "../../assets/images/popup-cark.png";
import pointer from "../../assets/images/wheel-pointer.png";
import FanCardModal from "../../components/Modals/FanCardModal/FanCardModal";
import GiveUpModal from "../../components/Modals/GiveUpModal/GiveUpModal";
import HowToUseModal from "../../components/Modals/HowToUseModal/HowToUseModal";
import PrizeReadyModal from "../../components/Modals/PrizeReadyModal/PrizeReadyModal";
import UserFormModal from "../../components/Modals/UserFormModal/UserFormModal";
import WheelCongratsModal from "../../components/Modals/WheelCongratsModal/WheelCongratsModal";
import { WheelInitData } from "../../core/models/custom/wheelInitData";
import { WheelHashDetailDto } from "../../core/models/dtos/wheelHashDetail.dto";
import { WheelOfferDto } from "../../core/models/dtos/wheelOffer.dto";
import { LoginMsisdnState } from "../../core/services/externalWheelService/loginMsisdn/types";
import { userFetchDetail } from "../../core/services/userService/getMe/actions";
import { wheelFetchHashDetail } from "../../core/services/wheelService/getHash/actions";
import { WheelHashDetailState } from "../../core/services/wheelService/getHash/types";
import {
  wheelConsumeHash,
  wheelResetHashConsume,
} from "../../core/services/wheelService/wheelConsume/actions";
import { WheelHashConsumeState } from "../../core/services/wheelService/wheelConsume/types";
import { Constants } from "../../core/utilities/constants";
import { IStore } from "../../core/utilities/reducers";
import Wheel4P from "./Wheel4p/Wheel4p";
import "./WheelPage.scss";
import { WheelOfferType } from "../../core/models/enums/wheelOfferType";
import { Helpers } from "../../core/utilities/helpers";

interface IProps {
  loginMsisdnState: LoginMsisdnState;
  wheelHashDetailState: WheelHashDetailState;
  wheelConsumeHashState: WheelHashConsumeState;
  wheelFetchHashDetail: (hash: string) => void;
  wheelConsumeHash: (hash: string) => void;
  wheelResetHashConsume: () => void;
  userFetchDetail: () => void;
}

interface IState {
  intervalId?: NodeJS.Timeout;
  isWheelCongratsModalVisible: boolean;
  isUserFormModalVisible: boolean;
  isGiveUpModalVisible: boolean;
  isFanCardModalVisible: boolean;
  isPrizeReadyModalVisible: boolean;
  isHowToUseModalVisible: boolean;
}

class WheelPage extends Component<IProps> {
  private readonly styleOdd: StyleType = {
    backgroundColor: "#FFED00",
    textColor: "#032F73",
  };
  private readonly styleEven: StyleType = {
    backgroundColor: "#FFF897",
    textColor: "#032F73",
  };

  state: IState = {
    intervalId: undefined,
    isWheelCongratsModalVisible: false,
    isUserFormModalVisible: false,
    isGiveUpModalVisible: false,
    isFanCardModalVisible: false,
    isPrizeReadyModalVisible: false,
    isHowToUseModalVisible: false,
  };

  componentDidMount() {
    if (this.props.loginMsisdnState.data) {
      this.props.wheelFetchHashDetail(this.props.loginMsisdnState.data.hash);
      this.props.userFetchDetail();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (
      prevProps.wheelHashDetailState.loading &&
      !this.props.wheelHashDetailState.loading &&
      this.props.wheelHashDetailState.data
    ) {
      this.setIntervalWheelCreated();
    }

    if (
      prevProps.wheelConsumeHashState.loading &&
      !this.props.wheelConsumeHashState.loading &&
      this.props.wheelConsumeHashState.data
    ) {
      if (this.wheelPrize()?.type === WheelOfferType.UNIQUE_CODE) {
        this.handlePrizeReadyModalVisible(true);
      } else {
        this.handleWheelCongratsModalVisible(true);
      }
    }
  }

  private setIntervalWheelCreated(): void {
    const intervalId = setInterval(() => {
      const wheelPointer =
        document.getElementById("wheel4p")?.firstChild?.lastChild;
      if (wheelPointer) {
        this.wheelCreated(wheelPointer);
      }
    }, 1);
    this.setState({ intervalId });
  }

  private wheelCreated(wheelPointer: any): void {
    wheelPointer.setAttribute("src", pointer);
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  private renderContent(): ReactNode {
    if (this.props.wheelHashDetailState.loading) {
      return <Spin className="wheel-loading" size="large" />;
    } else if (this.props.wheelHashDetailState.data) {
      return this.renderWheel(this.props.wheelHashDetailState.data);
    }
    return <React.Fragment />;
  }

  private getWheelInitDataFromHashDetail(
    hashDetail: WheelHashDetailDto
  ): WheelInitData {
    const data: WheelData[] = [];
    let winnerIndex: number = 0;
    hashDetail.offers.forEach((offer, index) => {
      data.push({
        option: offer.name,
        style: index % 2 === 0 ? this.styleEven : this.styleOdd,
      });
      if (offer.id === hashDetail.offerId) {
        winnerIndex = index;
      }
    });
    return { data, winnerIndex };
  }

  private handleWheelFinish = (): void => {
    if (this.props.loginMsisdnState.data) {
      this.props.wheelConsumeHash(this.props.loginMsisdnState.data.hash);
    }
  };

  private renderWheel(hashDetail: WheelHashDetailDto): ReactNode {
    const initData = this.getWheelInitDataFromHashDetail(hashDetail);
    return (
      <div className="wheel-wrapper mt-3">
        <img src={carkTitle} alt="title" />
        <Wheel4P
          wheelData={initData.data}
          winnerIndex={initData.winnerIndex}
          callbackFinished={this.handleWheelFinish}
        />
      </div>
    );
  }

  private wheelPrize = (): WheelOfferDto | undefined => {
    return this.props.wheelHashDetailState.data?.offers.find(
      (data) => data.id === this.props.wheelHashDetailState.data?.offerId
    );
  };

  private handleWheelCongratsModalVisible = (
    isWheelCongratsModalVisible: boolean
  ) => {
    this.setState({
      isWheelCongratsModalVisible,
    });
  };

  private handleUserModalVisible = (isUserFormModalVisible: boolean) => {
    this.setState({
      isUserFormModalVisible,
    });
  };

  private handleGiveUpModalVisible = (isGiveUpModalVisible: boolean) => {
    this.setState({
      isGiveUpModalVisible,
    });
  };

  private handleFanCardModalVisible = (isFanCardModalVisible: boolean) => {
    this.setState({
      isFanCardModalVisible,
    });
  };

  private handlePrizeReadyModalVisible = (
    isPrizeReadyModalVisible: boolean
  ) => {
    this.setState({
      isPrizeReadyModalVisible,
    });
  };

  private handleHowToUseModalVisible = (isHowToUseModalVisible: boolean) => {
    this.setState({
      isHowToUseModalVisible,
    });
  };

  private handleCloseWheelPrizeModal = () => {
    this.props.wheelResetHashConsume();
    this.handleWheelCongratsModalVisible(false);
    this.handleUserModalVisible(true);
  };

  private handleCloseUserFormModal = () => {
    this.handleUserModalVisible(false);
    this.handlePrizeReadyModalVisible(true);
    this.handleFanCardModalVisible(true);
  };

  private handleGiveUp = () => {
    this.handleUserModalVisible(false);
    this.handleGiveUpModalVisible(false);
    this.handleFanCardModalVisible(true);
  };

  private handlePrizeReadyClose = () => {
    this.handlePrizeReadyModalVisible(false);
    // this.handleFanCardModalVisible(true);//Buraya Constant ekle
     Helpers.goToTaraftarCard()
  };

  private renderPrizeModal = (): ReactNode => {
    const prize = this.wheelPrize();
    if (prize) {
      return (
        <PrizeReadyModal
          isVisible={this.state.isPrizeReadyModalVisible}
          prize={prize}
          code={this.props.wheelHashDetailState.data?.externalData}
          callbackClose={this.handlePrizeReadyClose}
          callbackHowToUse={() => this.handleHowToUseModalVisible(true)}
        />
      );
    }
  };

  render() {
    return (
      <div id="wheel-page" className="page">
        <div className="page-content">
          <img
            className="fener-logo"
            src={Constants.fenerLogo}
            alt="FenerLogo"
          />
          {this.renderContent()}
        </div>
        <WheelCongratsModal
          isVisible={this.state.isWheelCongratsModalVisible}
          prize={this.wheelPrize()}
          callbackClose={this.handleCloseWheelPrizeModal}
        />
        <UserFormModal
          isVisible={this.state.isUserFormModalVisible}
          prize={this.wheelPrize()}
          callbackClose={this.handleCloseUserFormModal}
          callbackGiveUp={() => this.handleGiveUpModalVisible(true)}
        />
        <GiveUpModal
          isVisible={this.state.isGiveUpModalVisible}
          callbackClose={() => this.handleGiveUpModalVisible(false)}
          callbackGiveUp={this.handleGiveUp}
        />
        <FanCardModal
          isVisible={this.state.isFanCardModalVisible}
          logo={carkIcon}
        />
        <HowToUseModal
          isVisible={this.state.isHowToUseModalVisible}
          terms={this.wheelPrize()?.terms}
          callbackClose={() => this.handleHowToUseModalVisible(false)}
        />
        {this.renderPrizeModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      wheelFetchHashDetail,
      wheelConsumeHash,
      wheelResetHashConsume,
      userFetchDetail,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    loginMsisdnState: store.loginMsisdn,
    wheelHashDetailState: store.wheelHashDetail,
    wheelConsumeHashState: store.wheelConsumeHash,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WheelPage);
